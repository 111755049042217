import * as React from "react"
import * as styles from "./index.module.scss"

interface Props {
  children: React.ReactNode
}

const Container: React.FC<Props> = ({ children }) => (
  <div className={styles.root}>{children}</div>
)

export default Container
