// extracted by mini-css-extract-plugin
export var about = "index-module--about--a6ad2";
export var about__headline = "index-module--about__headline--777c6";
export var about__stripe = "index-module--about__stripe--dc47d";
export var animation = "index-module--animation--08abe";
export var animation__animated = "index-module--animation__animated--2a9fc";
export var content = "index-module--content--98c8d";
export var content__about = "index-module--content__about--63ffb";
export var content__cover = "index-module--content__cover--b5552";
export var content__text = "index-module--content__text--e8824";
export var headline = "index-module--headline--0a8cb";
export var root = "index-module--root--d2fcc";
export var title = "index-module--title--6fa50";
export var title__wrapper = "index-module--title__wrapper--0f311";